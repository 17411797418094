.App {
  text-align: center;
}

.App-logo {
  padding-top: 10px;
  width: 5vmin;

} 

h1 {
  text-align: center;
  color: rgb(39, 41, 64);
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

h5 {
  text-align: center;
  padding-top: 5rem;
  color: rgb(222, 185, 146);
  
}

h2 {
  text-align: center;
  color: rgb(222, 185, 146);
}

h3 {
  text-align: center;
}

p {
  color: white;
  text-align: center;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  background-color: rgb(218, 218, 218);
}

.head_text {
  color: white;
}

.card {
  box-shadow: 5px 5px 5px #3398E1;
  text-align: center;
  border-width: 5px;
  border-color: rgb(15, 34, 75);
  border-radius: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.wrapper {
  display: flex;
  justify-content: flex-end;
}

.contactWrapper {
  display: flex;
  justify-content: flex-start;
}

.card-intro {
  max-width: 500px;
}

.card-header {
  border-width: 5px;
  border-color: rgb(15, 34, 75);
  border-radius: 15px;
  color: white;
}

h4 {
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

.logo {
  max-width: 100px;
  border-radius: 10px;
}

.App-header {
 
  max-height: 50vh;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.profilePicBryan {
  width: 240px;
  height: 210px;
}

.profilePicMike {
  width: 250px;
  height: 250px;
}

.companyBg {
  background-size: cover;
}

.serviceBg {
  background-size: contain;
  background-repeat: no-repeat;
}

.certBg {
  background-size: cover;
}

.cardCompany {
  box-shadow: 5px 5px 5px #3398E1;
  text-align: center;
  border-width: 5px;
  border-color: rgb(15, 34, 75);
  border-radius: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.cardHome {
  box-shadow: 5px 5px 5px #3398E1;
  text-align: center;
  border-width: 5px;
  border-color: rgb(15, 34, 75);
  border-radius: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.cardServices {
  box-shadow: 5px 5px 5px #3398E1;
  max-width: 50rem;

  border-width: 5px;
  border-color: rgb(15, 34, 75);
  border-radius: 15px;
  margin-right: 10px;
}

.wrapper-home img {
  width: 90%;
  height: 80%;
  margin-left: 30px;
}

.bg2 {
  width: 100%;
  height: 20%;
}
/* .card {
  background-color: rgba(0, 0, 0, 0.2);
  align-items: center;
  padding-bottom: 10px;
} 

 .card-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.col {
  margin-bottom: 10px;
}

.intro {

  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.cert {

  padding-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.share {
  max-width: 290px;
}

.dan {
  max-width: 250px;
}

button {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

li {
  text-align: left;
}

.btn-outline-primary {
  display: flex;
  justify-content: center;
}

.mike {
  max-width: 225px;
}

.about {
  border-width: 5px;
  border-color: rgb(15, 34, 75);
  border-radius: 15px;
  color: white;
}

.masthead-text {
  color: rgb(255, 255, 255);
}

.copyright {
  color: rgb(39, 41, 64);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}